import React, { useEffect, useRef, useState } from 'react';
import { usePlatformController } from '@magicyard/shared/platform/hooks/usePlatformController';
import './App.css';
import { DEFAULT_NAME, YardAndDisplayScreen } from './YardAndDisplayScreen';
import { SubmittableInput } from './submittable-input/SubmittableInput';
import { useFullscreen } from '@magicyard/shared/platform/hooks/useTryFullscreen';
import Lottie from 'lottie-react';
import loading from './assets/loading.json';
import { BurgerMenu } from './BurgerMenu';
import { CombinedYard, Profile, sendNavigationCommand } from '@magicyard/shared/platform/lib/api';
import { BaseController, Communication } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import { ReconnectionMessage } from '@magicyard/shared/src/ReconnectionMessage';
import { YardConnectionStatus } from './YardConnectionStatus';
import { randomChoice } from '@magicyard/utils/numberUtils';

export const DEFAULT_IMG = randomChoice([
  'https://i.ibb.co/RDJtZT6/local-player-big-avatar-Blue.png',
  'https://i.ibb.co/D1jgpM2/local-player-big-avatar-Green.png',
  'https://i.ibb.co/xLSC7fm/local-player-big-avatar-Purple.png',
  'https://i.ibb.co/c6nVYdh/local-player-big-avatar-Red.png',
  'https://i.ibb.co/WDwpVBJ/local-player-big-avatar-Yellow.png',
]);
const IS_DEBUG = false;

interface UnityInstance {
  SetFullScreen: (fullscreen: 1 | 0) => void;
  SendMessage: (objectName: string, methodName: string, value: string) => void;
  Quit: () => Promise<void>;
}

const useUnityInstance = () => {
  const canvas = useRef<HTMLCanvasElement>(document.querySelector('#unity-canvas'));
  const [unityInstance, setUnityInstance] = useState<UnityInstance | null>(null);

  useEffect(() => {
    console.log('Will load Unity');
    (window as any)
      .createUnityInstance(canvas.current, {
        dataUrl: `${process.env.REACT_APP_WEBGL_FOLDER}/Build/WebGL.data.unityweb`,
        frameworkUrl: `${process.env.REACT_APP_WEBGL_FOLDER}/Build/WebGL.framework.js.unityweb`,
        codeUrl: `${process.env.REACT_APP_WEBGL_FOLDER}/Build/WebGL.wasm.unityweb`,
        streamingAssetsUrl: `${process.env.REACT_APP_WEBGL_FOLDER}/StreamingAssets`,
        companyName: 'MagicYard',
        productName: 'Ticket to Ride',
      })
      .then((unityInstance: UnityInstance) => {
        setUnityInstance(unityInstance);
      })
      .then(() => {
        console.log('Unity Loaded');
      });
  }, []);

  return { instance: unityInstance, canvas: canvas.current };
};

export const App = () => {
  const unityInstance = useUnityInstance();
  const query = new URLSearchParams(window.location.search);

  useFullscreen();

  const [isTutorial, setIsTutorial] = useState(localStorage.getItem('tutorial') !== 'false');
  const { isReconnecting, yard, communication, profile, toRender, controller } = usePlatformController<{
    toRender: React.ReactNode;
    profile?: { onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => void; profile: Profile };
    controller?: BaseController;
    yard?: CombinedYard;
    communication?: Communication;
    isReconnecting?: boolean;
  }>(
    {
      onInitialLoading() {
        return { toRender: <Loading /> };
      },
      onGame({
        controller,
        onGameEnd,
        onStartAgain,
        onStartAgainOnline,
        onProfileUpdate,
        communication,
        isReconnecting,
      }) {
        const urlParams = new URLSearchParams(new URL((controller.gameStartArgs as { url: string }).url).search);
        const playerID = urlParams.get('playerID');
        const matchID = urlParams.get('matchID') || process.env.REACT_APP_MATCH_ID || 'default';
        const serverURL: string | undefined = urlParams.get('serverURL') ?? undefined;
        if (unityInstance)
          if (unityInstance.instance === null) {
            return {
              toRender: <Loading />,
              yard: controller.yard,
              communication: communication,
              isReconnecting: isReconnecting,
            };
          }
        const stringified = JSON.stringify({
          MatchID: matchID,
          ServerUrl: serverURL,
          PlayerID: playerID,
        });
        (window as any).playAgain = () => {
          onStartAgain();
          (window as any).playAgain = null;
        };
        unityInstance.instance.SendMessage('Game Igniter', 'ReceiveGameConnectionParamsExternal', stringified);
        unityInstance.canvas.style.opacity = '1';
        unityInstance.canvas.style.zIndex = '100';
        return {
          toRender: <div></div>,
          controller: controller,
          profile: { profile: controller.profile, onProfileUpdate: onProfileUpdate },
          yard: controller.yard,
          communication: communication,
          isReconnecting: isReconnecting,
        };
      },
      onGameLoading({ controller, onProfileUpdate, communication, isReconnecting }) {
        return {
          toRender: <Loading />,
          controller: controller,
          profile: { profile: controller.profile, onProfileUpdate: onProfileUpdate },
          yard: controller.yard,
          communication: communication,
          isReconnecting: isReconnecting,
        };
      },

      onInvalidYard({ controller, onDisplayScanned, onDisplayCodeEntered, onProfileUpdate, isReconnecting }) {
        return {
          toRender: <InvalidYard onSubmit={onDisplayCodeEntered} />,
          controller: controller,
          profile: { profile: controller.profile, onProfileUpdate: onProfileUpdate },
          isReconnecting: isReconnecting,
        };
      },
      onOnlineQueue({ controller, communication, isReconnecting }) {
        return {
          toRender: <div></div>,
          yard: controller.yard,
          communication: communication,
          isReconnecting: isReconnecting,
        };
      },
      onYard({ controller, communication, isReconnecting }) {
        return {
          toRender: <div></div>,
          yard: controller.yard,
          communication: communication,
          isReconnecting: isReconnecting,
        };
      },
      onYardWithDisplay({ controller, onProfileUpdate, onSubmitOnline, onSubmitLocal, communication, isReconnecting }) {
        const handleSubmit = (isTutorial: boolean) => {
          setIsTutorial(isTutorial);
          onSubmitLocal();
        };
        return {
          toRender: (
            <YardAndDisplayScreen
              controller={controller}
              onSubmitLocal={handleSubmit}
              onProfileUpdate={onProfileUpdate}
              onSubmitOnline={undefined}
            />
          ),
          controller: controller,
          profile: { profile: controller.profile, onProfileUpdate: onProfileUpdate },
          yard: controller.yard,
          communication: communication,
          isReconnecting: isReconnecting,
        };
      },
    },
    { displayId: query.get('displayId'), yardId: query.get('yardId') },
    DEFAULT_IMG,
    DEFAULT_NAME
  );

  return (
    <div className={'app_root'}>
      <ReconnectionMessage isReconnecting={isReconnecting} />
      {/*{yard !== undefined && (*/}
      {/*  <YardConnectionStatus*/}
      {/*    controllers={yard.controllers}*/}
      {/*    onClick={() => sendNavigationCommand({ command: 'toggleQr' })}*/}
      {/*  />*/}
      {/*)}*/}
      {profile !== undefined && (
        <BurgerMenu profile={profile.profile} onProfileUpdate={profile.onProfileUpdate} controller={controller} />
      )}
      {toRender}
    </div>
  );
};

const InvalidYard = (props: { onSubmit: (code) => void }) => {
  const [code, setCode] = useState('');
  return (
    <div className={'app_invalid-yard-container'}>
      <div className={'app_invalid-yard-title'}>Enter room code</div>
      <SubmittableInput
        autoFocus={false}
        tabIndex={-1}
        defaultValue={''}
        placeholder={'code..'}
        type={'number'}
        inputMode={'numeric'}
        forceEnglish={false}
        onSubmit={props.onSubmit}
      />
    </div>
  );
};

export const Popup = ({
  title,
  subTitle,
  body,
  footer,
}: {
  title: string;
  subTitle?: string;
  body?: React.ReactNode;
  footer: React.ReactNode;
}) => {
  return (
    <>
      <div className={'app-voice_chat_overlay_root-shadow'} />
      <div className={'app-voice_chat_overlay_root'}>
        <div className={'app-voice_chat_overlay_title'}>
          {title}
          {subTitle && <div className={'app-voice_chat_overlay_subtitle'}>{subTitle}</div>}
        </div>
        {body}
        <div className={'app-voice_chat_overlay_btn'}>{footer}</div>
      </div>
    </>
  );
};

export const Loading = () => {
  return (
    <div className={'app_loading-root'}>
      <div className={'app_loading-title'} />
      <div className={'app_loading-circle-root'}>
        <div className={'app_loading-circle-inner'} />
      </div>
    </div>
  );
};
