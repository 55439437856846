import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { App } from './App';

const getDemoData = (): { playerID: string; matchID: string; serverURL: string } | null => {
  const urlParams = new URLSearchParams(window.location.search);
  const playerID = urlParams.get('playerID');
  const matchID = urlParams.get('matchID') || process.env.REACT_APP_MATCH_ID;
  const serverURL = urlParams.get('serverURL');

  if (playerID === null || matchID === null || matchID === undefined) {
    return null;
  }

  return { playerID: playerID, matchID: matchID, serverURL: serverURL };
};

const demoData = getDemoData();
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
