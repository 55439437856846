import React, { useEffect, useState } from 'react';
import './YardAndDisplayScreen.css';
import { getYardInviteLink } from '@magicyard/utils';
import { SubmittableInput } from './submittable-input/SubmittableInput';
import TakePicDialog from './TakePicDialog';
import cameraIcon from './assets/camera.png';
import editPen from './assets/editPen.png';
import { Profile, Yard, YardWithGame } from '@magicyard/shared/platform/lib/api';
import { YardWithDisplayController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import { Dialog, Slide } from '@material-ui/core';
import { AppBody } from './AppBody';
import { Textfit } from 'react-textfit';
import { GameButton } from './components/GameButton';
import { Popup } from './App';
import { GAME } from '@magicyard/shared/games';

export const EditNamePopUp = ({
  defaultValue,
  onSubmit,
}: {
  defaultValue: string | null;
  onSubmit: (value: string) => void;
}) => {
  return (
    <Dialog
      open={defaultValue !== null}
      fullScreen
      TransitionComponent={Slide}
      TransitionProps={{
        // @ts-expect-error
        direction: 'up',
      }}
    >
      <AppBody>
        <div
          style={{
            height: '40vh',
            width: '80%',
            marginTop: '5vh',
            margin: '5vh',
            textAlign: 'center',
            fontSize: '4vh',
          }}
        >
          Choose a name
          <SubmittableInput
            placeholder={'Enter name!'}
            defaultValue={defaultValue ?? ''}
            forceEnglish={false}
            onSubmit={(v) => {
              if (v.length > 1) {
                onSubmit(v);
              }
            }}
          />
        </div>
      </AppBody>
    </Dialog>
  );
};

export const YardAndDisplayScreen = ({
  controller,
  onSubmitOnline,
  onSubmitLocal,
  onProfileUpdate,
}: {
  controller: YardWithDisplayController;
  onSubmitOnline: () => void;
  onSubmitLocal: (extras?: any) => void;
  onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => void;
}) => {
  const [isDev, setIsDev] = useState(0);
  const [showBeforeStartPopup, setShowBeforeStartPopup] = useState(false);

  if (controller.profile.name === DEFAULT_NAME) {
    return <EditNamePopUp defaultValue={''} onSubmit={(name) => onProfileUpdate({ name: name })} />;
  }

  const isDisabled = (GAME === null || !GAME.isPlayable) && isDev < 4;

  return (
    <>
      {showBeforeStartPopup && (
        <Popup
          title={'Is everyone ready?'}
          subTitle={`Don't leave anyone behind!`}
          body={<div className={'yard-and-display-popup_body'} />}
          footer={
            <div
              style={{
                width: '80%',
                marginBottom: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <GameButton
                onClick={() => {
                  onSubmitLocal();
                }}
              >
                Lets go!
              </GameButton>
              <GameButton
                onClick={() => {
                  setShowBeforeStartPopup(false);
                }}
                variant={'secondary'}
              >
                Not yet...
              </GameButton>
            </div>
          }
        ></Popup>
      )}
      {/*{GAME !== null && <GameBackground game={GAME} />}*/}
      <div className={'yard-and-display_body'}>
        <ProfilePart onProfileUpdate={onProfileUpdate} profile={controller.profile} forceRename={true} />
      </div>
      {/*<div className={'yard-and-display-screen_invite'}>*/}
      {/*  <GameButton onClick={handleInviteClick}>Invite friends</GameButton>*/}
      {/*</div>*/}
      <div className={'yard-and-display-screen_game'}>
        <GameButton
          onClick={
            isDisabled
              ? undefined
              : () => {
                  setShowBeforeStartPopup(true);
                }
          }
          disabled={isDisabled}
        >
          {GAME === null ? 'Select a game' : !GAME.isPlayable ? 'Coming soon!' : `Start!`}
        </GameButton>
      </div>
      <div
        style={{ position: 'absolute', top: 0, left: 0, height: 70, width: '10%' }}
        onClick={() => setIsDev((x) => x + 1)}
      />
    </>
  );
};

export const DEFAULT_NAME = 'Choosing name...';
export const ProfilePart = ({
  onProfileUpdate,
  profile,
  forceRename,
}: {
  onProfileUpdate: any;
  profile: Profile;
  forceRename: boolean;
}) => {
  const [isTakingPic, setIsTakingPic] = useState(false);
  const [isEditingName, setIsEditingName] = useState<string | null>(
    forceRename ? (profile.name === DEFAULT_NAME ? '' : null) : null
  );
  return (
    <>
      <EditNamePopUp
        defaultValue={isEditingName}
        onSubmit={(v) => {
          onProfileUpdate({ name: v });
          setIsEditingName(null);
        }}
      />
      <TakePicDialog
        open={isTakingPic}
        onClose={() => setIsTakingPic(false)}
        onTakePic={(x) => onProfileUpdate({ avatarUrl: x ?? undefined })}
      />
      <div className={'yard-and-display-screen_profile-part-root'}>
        <div className={'yard-and-display-screen_avatar-container'}>
          <div
            className={'yard-and-display-screen_avatar'}
            style={{
              backgroundImage: `url(${profile.avatarUrl})`,
            }}
            onClick={() => {
              setIsTakingPic(true);
            }}
          >
            <div className={'yard-and-display-screen_avatar-ring'} />

            <div
              className={'yard-and-display-screen_camera-container'}
              style={{ backgroundImage: `url(${cameraIcon})` }}
            >
              <div className={'yard-and-display-screen_camera'} style={{}} />
            </div>
          </div>

          <div className={'yard-and-display-screen_name'} onClick={() => setIsEditingName(profile.name)}>
            <Textfit
              forceSingleModeWidth={true}
              max={26}
              mode={'single'}
              style={{ width: '80%', marginRight: '10vw', marginLeft: '5vw' }}
            >
              {profile.name}
            </Textfit>
            <div
              style={{
                width: '3vh',
                height: '3vh',
                backgroundImage: `url(${editPen})`,
                position: 'absolute',
                backgroundSize: 'contain',
                right: '8vw',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

async function share(data: ShareData): Promise<void> {
  (window.navigator as any).share(data, SHARE_POLYFILL_OPTIONS).catch((error: any) => {
    console.error(error);
  });
}

const SHARE_POLYFILL_OPTIONS = {
  copy: true,
  email: true,
  print: true,
  sms: true,
  messenger: false,
  facebook: false,
  whatsapp: true,
  twitter: true,
  linkedin: false,
  telegram: true,
  skype: false,
  pinterest: false,
};

export const GameBackground = ({ game }: { game: any }) => {
  return (
    <div
      key={game.id}
      className={'yard-and-display-screen_bg'}
      style={{
        backgroundImage: `linear-gradient(0, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%), url(${game.bg})`,
      }}
    />
  );
};
