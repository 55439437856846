import React, { CSSProperties } from 'react';
import './GameButton.css';

export interface GameButtonProps {
  disabled?: boolean;
  isGreen?: boolean;
  onClick: (() => void) | undefined;
  background?: string;
  style?: CSSProperties;
  rootStyle?: CSSProperties;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary';
  className?: string;
}

export const GameButton = (props: GameButtonProps) => {
  const { children, disabled, isGreen, onClick, style, background, rootStyle } = props;
  return (
    <div className={'game_button-root'}>
      <div
        className={`game_button-btn ${disabled ? 'game_button-disabled' : ''} game_button-btn_${
          props.variant ?? 'primary'
        } ${props.className ?? ''}`}
        style={rootStyle}
        onClick={!disabled ? onClick : undefined}
      >
        {children}
      </div>
    </div>
  );
};
