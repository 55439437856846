import React, { useState } from 'react';
import styles from './BurgerMenu.module.css';
import { Profile } from '@magicyard/shared/platform/lib/api';
import useToggleFullscreen from '@magicyard/shared/platform/hooks/useToggleFullscreen';
import EnterFullScreen from './assets/icons/enterFullscreen.webp';
import ExitFullScreen from './assets/icons/exitFullscreen.webp';
import SwitchGame from './assets/icons/switchGame.webp';
import Discord from './assets/icons/discord.webp';
import Bug from './assets/icons/bug.webp';
import Feedback from './assets/icons/feedback.webp';
import { Dialog, Slide } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { AppBody } from './AppBody';
import { BaseController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import { SubmittableInput } from './submittable-input/SubmittableInput';
import { ProfilePart } from './YardAndDisplayScreen';

export const BurgerMenu = ({
  profile,
  onProfileUpdate,
  leaveGame,
  controller,
}: {
  profile: Profile;
  onProfileUpdate: (profile: Partial<Profile>) => void;
  leaveGame?: () => void;
  controller: BaseController | undefined;
}) => {
  const { toggleFullscreen, isFullscreen } = useToggleFullscreen();
  const [isOpen, setIsOpen] = useState(false);
  const [dialogOpts, setDialogOpts] = useState<{
    title: string;
    subTitle: string;
    onSubmit: (t: string) => void;
  } | null>(null);
  return (
    <>
      <FeedbackDialog
        isOpen={dialogOpts !== null}
        onClose={() => setDialogOpts(null)}
        title={dialogOpts?.title ?? ''}
        subTitle={dialogOpts?.subTitle ?? ''}
        onSubmit={dialogOpts?.onSubmit ?? (() => undefined)}
      />
      <div
        className={`${styles.burgerContainer} ${isOpen ? styles.burgerContainerActive : ''}`}
        onClick={() => setIsOpen((old) => !old)}
      >
        <div className={`${styles.burgerLine} ${isOpen ? styles.burgerLineTopActive : ''}`} />
        <div className={styles.burgerLine} />
        <div className={`${styles.burgerLine} ${isOpen ? styles.burgerLineBottomActive : ''}`} />
      </div>
      <div
        className={`${styles.root} ${isOpen ? styles.rootOpen : ''}`}
        onClick={(e) => (e.target === e.currentTarget ? setIsOpen(false) : () => undefined)}
      >
        <div className={`${styles.body} ${isOpen ? styles.bodyOpen : styles.bodyClosed}`} onTransitionEnd={() => {}}>
          {/*<div className={styles.title}>*/}
          {/*  <ProfilePart onProfileUpdate={onProfileUpdate} profile={profile} forceRename={false} />*/}
          {/*</div>*/}
          <div className={styles.bodyElementsContainer}>
            <div
              className={styles.bodyElement}
              onClick={() => {
                setIsOpen(false);
                toggleFullscreen();
              }}
            >
              <div
                className={styles.bodyElementIcon}
                style={{ backgroundImage: `url(${isFullscreen ? ExitFullScreen : EnterFullScreen})` }}
              />{' '}
              {isFullscreen ? 'Leave ' : 'Enter '}fullscreen
            </div>
            {leaveGame && (
              <div
                className={styles.bodyElement}
                onClick={() => {
                  if (window.confirm('Are you sure?')) {
                    leaveGame();
                    setIsOpen(false);
                  }
                }}
              >
                <div className={styles.bodyElementIcon} style={{ backgroundImage: `url(${SwitchGame})` }} />
                Switch game
              </div>
            )}
          </div>

          <div className={styles.bodyElementsContainer}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={'https://discord.gg/E6GYMY2J'}
              className={styles.bodyElement}
            >
              <div className={styles.bodyElementIcon} style={{ backgroundImage: `url(${Discord})` }} />
              Join our discord
            </a>

            <div
              className={styles.bodyElement}
              onClick={() =>
                setDialogOpts({
                  title: 'Describe your issue in detail',
                  subTitle: 'We work hard on fixing reported issues',
                  onSubmit: (t) => {
                    setDialogOpts(null);
                  },
                })
              }
            >
              <div className={styles.bodyElementIcon} style={{ backgroundImage: `url(${Bug})` }} />
              Report a bug
            </div>
            <div
              className={styles.bodyElement}
              onClick={() =>
                setDialogOpts({
                  title: `We're always happy to hear your feedback`,
                  subTitle: 'We highly value your opinion',
                  onSubmit: (t) => {
                    setDialogOpts(null);
                  },
                })
              }
            >
              <div className={styles.bodyElementIcon} style={{ backgroundImage: `url(${Feedback})` }} />
              Send feedback
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const FeedbackDialog = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  subTitle,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (t: string) => void;
  title: string;
  subTitle: string;
}) => {
  return (
    <Dialog
      open={isOpen}
      fullScreen={true}
      TransitionComponent={Slide}
      TransitionProps={{
        // @ts-expect-error
        direction: 'up',
      }}
    >
      <AppBody>
        <div onClick={onClose} style={{ alignSelf: 'flex-end' }}>
          <Close />
        </div>
        <div style={{ textAlign: 'center', fontSize: 32, width: '80%' }}>{title}</div>
        <div style={{ height: '10vh', width: '80%', marginBottom: '5vh' }}>
          <SubmittableInput type={'text'} forceEnglish={false} onSubmit={onSubmit} multiline={true} />
        </div>
        <div style={{ textAlign: 'center' }}>{subTitle}</div>
      </AppBody>
    </Dialog>
  );
};
